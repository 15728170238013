#contact {
  height: 50vh;
  position: relative;
  h2 {
    margin-left: 1em;
  }
}
.contact__main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  padding-top: 0;
}
.contact__text_primary {
  line-height: 35px;
  text-align: center;
  max-width: 80%;
}
.contact__span-email {
  color: #424242;
  &:hover {
    color: #eee;
  }
}

.contact__form {
  display: flex;
  flex-direction: column;
  width: 65%;
  padding: 2em 0;

  .contactInputField {
    margin: 1em 0;
    padding: 1.5em 0;
    border: none;
    background-color: transparent;
    border-bottom: 3px solid #fff;
    border-radius: 5px;
    outline: none;
    @media (max-width: 1000px) {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
  }
  .contactSubmitButton {
    width: 200px;
    margin: 2em auto;
    background-color: $darkred;
    padding: 1em;
    border-radius: 5px;
  }
  .contact__form-names {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      display: block;
    }
  }
  label {
    display: none;
  }

  ::placeholder,
  input {
    color: #fff;
    text-align: center;
  }
  textarea {
    resize: none;
    height: 100px;
    color: #fff;
  }
}
.fadeOut {
  animation: 500ms fadeOut;
  animation-fill-mode: forwards;
}
.fadeIn {
  animation: 500ms fadeIn;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}
.contact__form_response {
  text-align: center;
  line-height: 50px;
  width: 100%;
  font-size: 1.5em;
  position: absolute;
  top: 90%;
  right: 50%;
  transform: translate(50%);
  z-index: 2;
  opacity: 0;
  padding: 1em;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
