#aboutme {
  h2 {
    text-align: right;
    margin-right: 1em;
  }
}

.aboutme_content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}

.about_picture_figure {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}

.rounded_marquis {
  height: 300px;
  width: 320px;
  border-radius: 10px;
  display: block;

  @media (max-width: $mainheroBreakpointSmall) {
    height: 200px;
    width: 250px;
  }
}

.aboutme__paragraph {
  // margin-top: 1.5em;
  padding: 1.5rem;
  font-size: 1.25em;
  letter-spacing: 2px;
  line-height: 2rem;
  display: block;
  font-weight: 400;

  @media (max-width: $mainheroBreakpointSmall) {
    font-size: 0.75em;
    // line-height: 30px;
  }

  &::first-letter {
    margin-left: 2em;
  }

  .important-bold {
    font-weight: 900;
    // color: blue;
    font-style: italic;
    font-weight: 0.25em;
  }
}

.bottom {
  margin-top: none;
  // padding: 0 2em 2em 2em;
}
