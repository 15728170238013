$lightblue: rgb(78, 169, 218);
$lightred: #f42f42;

$brightred: #ee5050;
$darkred: #cf3a39;
// font-family: 'Fredoka One', cursive;
// font-family: 'Luckiest Guy', cursive;
// font-family: 'Paytone One', sans-serif;

// $fredoka: 'Fredoka One', cursive;
$roboto: 'Roboto Mono', monospace;

//MEDIA BREAKPOINTS
$projectsBreakpoint: 700px;
$mainheroBreakpoint: 950px;
$mainheroBreakpointSmall: 550px;
$techIconsBreakpoint: 600px;
$projectBackgroundBreakpoint: 500px;
$mainheroIconBreakpoint: 450px;
