//VARIABLES
@import './assets/scss/variables';

//NORMALIZE
@import './assets/scss/normalize';

//ANIMATIONS FROM ANIMATE.CSS
@import './assets/scss/animate';

//HEADER STYLES
@import './assets/scss/header';

//MAINHERO STYLES
@import './assets/scss/mainhero';

//ABOUT ME STYLES
@import './assets/scss/aboutme';

//PROJECTS STYLES
@import './assets/scss/projects';

//CONTACT STYLES
@import './assets/scss/contact';

//OVERALL BODY STYLES
@import './assets/scss/body';

body {
  animation: fadeInPageLoad 2s;
}

@keyframes fadeInPageLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
